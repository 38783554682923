import React, { Dispatch, SetStateAction, FC } from 'react';
import DatePicker from 'react-datepicker';

import moment from 'moment';

import './styles.css';

type DateFieldProps = {
  firstDate: Date | undefined;
  setFirstDate: Dispatch<SetStateAction<Date | undefined>>;
  defaultFirstDayPay: string;
};

const ADDITIONAL_DAYS_TO_FIRST_PAY = 14;

export const DateField: FC<DateFieldProps> = ({
  firstDate,
  setFirstDate,
  defaultFirstDayPay,
}) => {
  const maxDate = moment(new Date(defaultFirstDayPay)).add(
    ADDITIONAL_DAYS_TO_FIRST_PAY,
    'days',
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DatePicker
      dateFormat="dd.MM.yyyy"
      selected={firstDate}
      onChange={(date: Date) => setFirstDate(date)}
      minDate={new Date(defaultFirstDayPay)}
      maxDate={maxDate.toDate()}
    />
  );
};
