import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import { CURRENCY } from '../../../constants';

import { RangeSlider } from '../../common/RangeSlider';
import Offer from '../../loanRequest/offer';

import { getSliderMarks, resolveLoanTerm } from '../../../utils/helper';

import { IProduct } from '../../../types';

type CalculatorBlockProps = {
  defaultValueAmount: number;
  sliderSettings: any;
  prices: any;
  discountCode?: string;
  loanSettings: any;
  setLoanSettings: any;
  currentCondition: any;
  onGetPricesForAmount: any;
  currentProduct: IProduct;
  setCustomAmount: any;
};

const useStyles = makeStyles(() => ({
  grid: {
    justifyContent: 'space-between',
    margin: 0,
  },
  block: {
    maxWidth: 'calc(50% - 20px)',
  },
}));

export const CalculatorBlock: FC<CalculatorBlockProps> = ({
  defaultValueAmount,
  sliderSettings,
  prices,
  discountCode,
  loanSettings,
  currentCondition,
  setLoanSettings,
  onGetPricesForAmount,
  currentProduct,
  setCustomAmount,
}) => {
  const classes = useStyles();

  const [amount, setAmount] = useState(loanSettings.amount);
  const [duration, setDuration] = useState(loanSettings.duration);

  const onBlurCustomAmount = (value: number): void => {
    let current = value;
    if (value > sliderSettings.maxAmount) {
      setAmount(sliderSettings.maxAmount);
      current = sliderSettings.maxAmount;
    } else if (value < sliderSettings.minAmount) {
      setAmount(sliderSettings.minAmount);
      current = sliderSettings.minAmount;
    } else {
      setAmount(value);
    }

    onGetPricesForAmount({
      productId: currentProduct.id,
      params: {
        amount: current,
      },
    });

    setLoanSettings({ amount: current, duration, discount_code: discountCode });
  };

  const productType = currentProduct.type;

  const onChangeCommitted = (): void => {
    setLoanSettings({ amount, duration, discount_code: discountCode });
  };

  const amountList = useMemo(
    () =>
      prices[loanSettings.duration]
        ? Object.keys(prices[loanSettings.duration])
        : [],
    [loanSettings.duration, prices],
  );

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12} sm={6} className={classes.block}>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '10px',
            }}
          >
            <Typography
              style={{
                marginRight: '10px',
              }}
            >
              Выберите сумму:
            </Typography>
            <TextField
              type="number"
              id="standard-basic"
              value={amount || defaultValueAmount}
              onBlur={(e) => onBlurCustomAmount(+e.target.value)}
              onChange={(e) => {
                setCustomAmount(true);
                setAmount(+e.target.value);
              }}
            />
            <Typography
              style={{
                marginLeft: '10px',
              }}
            >
              {CURRENCY}
            </Typography>
          </div>
          <RangeSlider
            value={amount}
            onChange={(event: ChangeEvent, amount: number) => {
              setCustomAmount(false);
              setAmount(amount);
            }}
            onChangeCommitted={onChangeCommitted}
            min={sliderSettings.minAmount}
            max={sliderSettings.maxAmount}
            step={sliderSettings.stepAmount}
            marks={getSliderMarks(amountList)}
          />
        </div>
        <div>
          <Typography gutterBottom>
            Выберите срок: {duration}
            {resolveLoanTerm(productType)}
          </Typography>
          <RangeSlider
            value={duration}
            onChange={(event: ChangeEvent, v: number) => {
              setDuration(v);
            }}
            onChangeCommitted={onChangeCommitted}
            min={sliderSettings.minTerm}
            max={sliderSettings.maxTerm}
            step={sliderSettings.stepTerm}
            marks={getSliderMarks(Object.keys(prices)) || false}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.block}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Занимаемая сумма:</TableCell>
              <TableCell>
                {loanSettings.amount} {CURRENCY}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Сумма к возврату:</TableCell>
              <TableCell>
                {currentCondition?.return_amount || '-'} {CURRENCY}
              </TableCell>
            </TableRow>
            {productType !== 'credit7' && (
              <TableRow>
                <TableCell>Ежемесячый платеж</TableCell>
                <TableCell>
                  {currentCondition?.regular_payment?.amount || '-'} {CURRENCY}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Первый платеж</TableCell>
              <TableCell>
                {moment(
                  currentCondition?.regular_payment?.first_pay_date,
                ).format('DD.MM.YYYY')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Срок</TableCell>
              <TableCell>
                {loanSettings.duration}
                {resolveLoanTerm(productType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Дата возврата</TableCell>
              <TableCell>
                {moment(currentCondition?.return_date).format('DD.MM.YYYY') ||
                  '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center">
                <Offer
                  productId={currentProduct.id}
                  duration={loanSettings.duration}
                  amount={loanSettings.amount}
                  language="ro"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
