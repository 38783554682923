import { IGiveOutMethods, ILanguage } from '../types';

export * from './paths';

export const ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR';

export const CORE = '/core';
export const THIRD_PARTY = '/third-party';

export const REQUEST_LOAN_STATUS = 0;
export const SIGNED_LOAN_STATUS = 1;
export const ACTIVE_LOAN_STATUS = 2;
export const CLOSED_LOAN_STATUS = 3;
export const REJECTED_LOAN_STATUS = 4;
export const GIVE_OUT_PROCESSING_LOAN_STATUS = 10;
export const GIVE_OUT_FAILED_LOAN_STATUS = 15;
export const AWAITING_SIGN_CONTRACT_STATUS = 26;
export const OVERDUE_STATUS = 21;
export const AWAITING_COLLECTOR_STATUS = 23;
export const COLLECTOR_IN_PROGRESS_STATUS = 24;
export const SOLD_STATUS = 27;

export const CREDIT_7 = 'credit7';
export const CREDIT_365 = 'credit365';

export const GIVE_OUT_METHODS: IGiveOutMethods = {
  1: { title: 'Iban', isRequiresAccount: true },
  2: { title: 'Наличные', isRequiresAccount: false },
  3: { title: 'E-Wallet RunPay', isRequiresAccount: true },
  4: { title: 'E-Wallet BPay', isRequiresAccount: true },
  5: { title: 'E-Wallet Paynet', isRequiresAccount: true },
  7: { title: 'Partner', isRequiresAccount: false },
};

export const LANGUAGE_LIST: ILanguage[] = [
  { value: 'ru', title: 'RU' },
  { value: 'ro', title: 'MD' },
];

export const CURRENCY = 'MDL';

export const CODE_SIGN_METHOD = 1;
export const DOCUMENT_SIGN_METHOD = 2;
